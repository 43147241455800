import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.scss';
import MainPage from '../MainPage/MainPage';
import Layout from '../Layout';
import LegalInfo from './LegalInfo';

function App() {


  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />} >
          <Route index element={<MainPage />} />

          <Route path="/mentions-legales/" element={<LegalInfo />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
