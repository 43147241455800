import { useTranslation } from "react-i18next"
import profileImg from '../assets/img/profile.jpg'

const styles = {
  header: {
    marginTop: '20px',
    height: '100%',
    color: '#fff',
  },
  profileImgContainer: {
    margin: '20px 0',
    display: 'flex',
    height: '150px'
  },
  profileImg: {
    height: '100%',
    width: '150px',
    borderRadius: '50%',
    backgroundRepeat: "noRepeat",
    backgroundSize: "150px",
  },
  h1: {
    fontSize: '1.5rem',
    marginTop: 0,
    marginBottom: 0,
  },
  h2: {
    marginTop: 0,
    marginBottom: '1rem',
    fontSize: '1.8rem'
  },
  h3: {
    marginTop: '2rem',
    marginBottom: '0.5rem',
  },
  link: {
    color: '#4E937A',
    textDecoration: 'none',
    fontSize: '1.2rem',
    display: 'flex',
    gap: '0.5em',
    alignItems: 'center',
    flexWrap: 'no-wrap',
  },
  flexContainer: {
    display: 'flex',
  }
}

export default function MainPage() {
  const { t, i18n } = useTranslation()
  const cv_path = i18n.language === 'fr' ? "/cv_mariaux_fr.pdf" : "/cv_mariaux_en.pdf"

  return (
    <>
      <header style={styles.header}>
        <div style={styles.profileImgContainer}>
          <img
            src={profileImg}
            alt='profile'
            style={styles.profileImg}
          />
        </div>
        <div className="header-content">
          <h2 style={styles.h2}>Sébastien Mariaux</h2>
          <div className='description'>
            {t('mainPage.description')}
          </div>
        </div>
      </header>
      <section>
        <h3 style={styles.h3}>{t('mainPage.skills')}</h3>
        Ruby, Ruby-on-Rails, Python, Django, JavaScript, React, PostgreSQL, Git, Docker
      </section>
      <section>
        <h3 style={styles.h3}>{t('menu.about')}</h3>
        <div className='links-container'>
          <div style={styles.flexContainer}>
            <a href={cv_path}
              target="_blank"
              rel="noreferrer"
              style={styles.link}
              title="CV">
              <i className="fas fa-file-export"></i>{t('mainPage.cv')}
            </a>
          </div>
          <div style={styles.flexContainer}>
            <a href="https://www.linkedin.com/in/sebastienmariaux/"
              target="_blank"
              rel="noreferrer"
              style={styles.link}
              title="LinkedIn">
              <i className="fab fa-linkedin"></i>LinkedIn
            </a>
          </div>
          <div style={styles.flexContainer}>
            <a href="https://github.com/sebastien-mariaux"
              className="socials"
              target="_blank"
              style={styles.link}
              rel="noreferrer"
              title="GitHub">
              <i className="fab fa-github"></i>Github
            </a>
          </div>
        </div>
      </section >
      <section>
        <h3 style={styles.h3}>{t('menu.extra')}</h3>
        {
          i18n.language === 'fr' ?
            ("Je vis à Aix-en-Provence et je suis disponible pour des missions freelance dans la région ou en télétravail.")
            :
            (
              <>
                <p>I currently live in Aix-en-Provence, France.
                  I am available to work remotely (I can adjust my working hours to your time zone)
                  and I am planning to relocate to the CST time zone in 2025.</p>
                <p>I speak English fluently - I have been working in an international environment for the past 2 years. </p>
              </>
            )
        }
      </section>
    </>
  )
}